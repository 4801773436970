import { createSlice } from '@reduxjs/toolkit';
import axios from '@utils/axios';

import { AppDispatch } from '@store/index';
import {
  ChildCommunityState,
  FetchChildCommunitiesParams,
  ChildCommunity,
  FetchChildCommunitiesResponse,
} from './types';

const childCommunitySlice = createSlice({
  name: 'childCommunityStore',
  initialState: {
    childCommunities: [],
    totalChildCommunities: 0,
    isFetchingChild: false,
    isShowChildCommunityModal: false,
    isLastPage: false,
  } as ChildCommunityState,
  reducers: {
    setIsFetchingChild: (state, { payload }: { payload: boolean }) => {
      state.isFetchingChild = payload;
    },
    setChildTotal: (state, { payload }: { payload: number }) => {
      state.totalChildCommunities = payload;
    },
    setChildCommunities: (state, { payload }: { payload: ChildCommunity[] }) => {
      if (payload.length) {
        const firstCommunityId = payload[0].communityId;

        const hasCommunityId = state.childCommunities.find(
          (child) => child.communityId === firstCommunityId,
        );

        state.childCommunities = hasCommunityId ? payload : [...state.childCommunities, ...payload];
        state.isLastPage = state.totalChildCommunities <= state.childCommunities.length;
      } else {
        if (state.childCommunities.length && !payload.length) {
          state.childCommunities = [...state.childCommunities];
          state.isLastPage = state.totalChildCommunities <= state.childCommunities.length;
        } else {
          state.childCommunities = [];
          state.totalChildCommunities = 0;
          state.isLastPage = true;
        }
        state.childCommunities = [];
      }
    },
    setIsShowChildCommunityModal: (state, { payload }: { payload: boolean }) => {
      state.isShowChildCommunityModal = payload;
    },
    resetState: (state) => {
      state.childCommunities = [];
      state.totalChildCommunities = 0;
      state.isFetchingChild = false;
      state.isLastPage = false;
      state.isShowChildCommunityModal = false;
    },
  },
});

export const fetchChildCommunities =
  ({ mainCommunityId, page = 1, limit = 20, isLastPage }: FetchChildCommunitiesParams) =>
  async (dispatch: AppDispatch) => {
    if (isLastPage) return null;

    dispatch(childCommunitySlice.actions.setIsFetchingChild(true));

    try {
      const response = await axios.get<FetchChildCommunitiesResponse>(`/communities`, {
        params: { mainCommunityId, page, limit },
      });

      if (response.data.data.length) {
        const childCommunities: ChildCommunity[] = response.data.data.map((community) => ({
          communityId: community.id,
          title: community.title,
          slogan: community.page?.slogan || '',
          avatar: community.page?.avatar || '',
          name: community.name || null,
        }));

        dispatch(childCommunitySlice.actions.setChildTotal(response.data.total));
        dispatch(
          childCommunitySlice.actions.setChildCommunities(
            childCommunities.length ? childCommunities : [],
          ),
        );
        dispatch(childCommunitySlice.actions.setIsFetchingChild(false));
      } else {
        dispatch(childCommunitySlice.actions.setChildCommunities([]));
        dispatch(childCommunitySlice.actions.setIsFetchingChild(false));
      }
    } catch (e) {
      console.log('Не удалось запросить связаные сообщества');
      dispatch(childCommunitySlice.actions.setIsFetchingChild(false));
    }
  };

export default childCommunitySlice;
